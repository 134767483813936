import { memo } from 'react'

const Layout = ({children, showLastStep, showResult}) => {

	return (
		<>
			<div className="w-full flex items-center justify-center px-4 dummy-img">
				<div className="w-full max-w-[924px]">
					{ children }
				</div>
			</div>
		</>
	)		
}

export default memo(Layout)