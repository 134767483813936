import React, { memo } from 'react'

const ThemeSelector = ({children}) => {
	
	const Theme1 = React.lazy(() => import('./Theme1'))
	const Theme2 = React.lazy(() => import('./Theme2'))

	return (
		<>
			<React.Suspense fallback={() => null}>
				{window.location.pathname === '/v4' && <Theme2 />}
				{window.location.pathname === '/' && <Theme1 />}
			</React.Suspense>

			{children}
		</>
	)
}

export default memo(ThemeSelector)