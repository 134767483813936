import { memo } from 'react'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'

const ThankyouModal = (props) => {
	const { show, handleClose } = props

	return (
		<Modal open={show} onClose={handleClose} classNames={{ overlay: 'customOverlay', modal: 'thanku-modal w-72 md:w-96 lg:w-full' }}>
			<div className='thanku-modal-info p-4 lg:p-12'>
				<h3 className='font-medium text-2xl lg:text-4xl leading-7 mb-4 lg:mb-7 text-secondary text-center'>Thank You!</h3>
				<p className='font-normal text-base lg:text-xl leading-7 text-black-200 mb-4 md:mb-7 text-center'>Your message has been sent successfully.</p>

				<div className='block text-center'>
					<button className='next-btn  py-[14px] !bg-[#0a1b4a] px-5 font-medium rounded-[12px] text-base leading-5 text-white' onClick={handleClose}>Okay</button>
				</div>
			</div>
		</Modal>
	)
}

export default memo(ThankyouModal)