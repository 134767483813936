import { ScreeningProvider } from 'context'
import Screening from 'containers/screeningContainer'
import Screeningv4 from 'containers/screeningv4Container'

export const routes = [
	{
		path: '/',
		element: (
			<ScreeningProvider>
				<Screening />
			</ScreeningProvider>
		),
	},
	{
		path: 'v4',
		element: (
			<ScreeningProvider>
				<Screeningv4 />
			</ScreeningProvider>
		),
	},
]
