import { memo } from 'react'
import LeftArrow from "assets/v4/svg/left-arrow.svg"
import RefreshIcon from "assets/v4/svg/refresh-icon.svg"
import CustomProgressBar from './CustomProgressBar'

const ResetBtnGroup = (props) => {

	const { step, progress_percent, changeStep, showResult, restartScreening, showOtherAreaStep, terminateScreening } = props

	return (
		<div className='flex gap-3 mb-3'>
			{!showOtherAreaStep && step > 1 &&
				<>
					{!showResult &&
						<button onClick={() => changeStep(step - 1)} className='bg-white blur-[150] text-[#0a1b4a] w-6 md:w-8 max-w-[30px] h-6 md:h-8 max-h-[30px] flex items-center justify-center border-[2px] border-border-grey rounded-[8px]'><img src={LeftArrow} alt="arrow" className="h-auto w-5" /> </button>
					}

					<button onClick={restartScreening} className='bg-white blur-[150] text-[#0a1b4a] w-6 md:w-8 max-w-[30px] h-6 md:h-8 max-h-[30px] flex items-center justify-center border-[2px] border-border-grey rounded-[8px]'><img src={RefreshIcon} alt="arrow" className="h-auto w-5" /> </button>
					<CustomProgressBar percent={progress_percent} height={30} />
				</>
			}
			{(showOtherAreaStep || terminateScreening) &&
				<button onClick={restartScreening} className='bg-white blur-[150] text-[#0a1b4a] w-6 md:w-8 max-w-[30px] h-6 md:h-8 max-h-[30px] flex items-center justify-center border-[2px] border-border-grey rounded-[8px]'><img src={RefreshIcon} alt="arrow" className="h-auto w-5" /> </button>	
			}
		</div>
	)
}

export default memo(ResetBtnGroup)