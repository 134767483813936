import { memo } from 'react'
import { Field } from 'formik'
import UserIcon from 'assets/v4/svg/user-icon.svg'
import EmailIcon from 'assets/v4/svg/email-icon.svg'
import CallIcon from 'assets/v4/svg/call-icon.svg'
import DownArrow from 'assets/v4/svg/down-arrow.svg'
import CalendarIcon from 'assets/v4/svg/calendar.svg'
import { Link } from 'react-router-dom'
import InputMask from 'react-input-mask'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
moment.tz.setDefault('America/Los_Angeles')

const GetResultStep = (props) => {

	const { states, errors } = props
	
	const handleCheckboxChange = (e) => props.setFieldValue('tnc', e.target.checked)

	return (
		<div className='block'>
			<p className='font-normal text-[20px] text-base leading-7 mb-4 text-white'>Your Case Results are ready! Please provide your information to see the results.</p>
			<div className='w-full flex flex-wrap -mx-2 -mb-4'>
				
				<div className='w-6/12 px-2 mb-4'>
					<div className="relative main-input-div">
						<Field name="first_name">
							{({ field, form }) => (
								<>
									<input type="text" name="first_name" className={`form-input ${errors.first_name ? 'border-red' : ''}`} value={props.values.first_name} placeholder="Enter First Name" onChange={props.handleChange} />

									<div className="form-input-icon">
										<img src={UserIcon} className='' alt="profile_icon" />
									</div>
								</>
							)}
						</Field>
					</div>
				</div>

				<div className='w-6/12 px-2 mb-4'>
					<div className="relative main-input-div">
						<Field name="last_name">
						{({ field, form }) => (
							<>
								<input type="text" name="last_name" className={`form-input ${errors.last_name ? 'border-red' : ''}`} value={props.values.last_name} placeholder="Enter Last Name" onChange={props.handleChange} />

								<div className="form-input-icon">
									<img src={UserIcon} className='' alt="profile_icon" />
								</div>
							</>
						)}
						</Field>
					</div>

					{/*errors.last_name &&
						<div className="mt-1 text-sm text-red">{errors.last_name}</div>
					*/}

				</div>

				<div className='w-6/12 px-2 mb-4'>
					<div className="relative main-input-div">
						<Field name="email">
							{({ field, form }) => (
								<>
									<input type="email" name="email" className={`form-input ${errors.email ? 'border-red' : ''}`} value={props.values.email} placeholder="Enter Email Address" onChange={props.handleChange} />

									<div className="form-input-icon">
										<img src={EmailIcon} className='' alt="email_icon" />
									</div>	
								</>
							)}
						</Field>
					</div>

					{/*errors.email &&
						<div className="mt-1 text-sm text-red">{errors.email}</div>
					*/}
				</div>

				<div className='w-6/12 px-2 mb-4'>
					<div className="relative main-input-div">
						<Field name="phone">
							{({ field, form }) => (
								<>
									<InputMask mask="+1 (999) 999-9999" name="phone" className={`form-input ${errors.phone ? 'border-red' : ''}`} placeholder="Phone" value={props.values.phone} onChange={props.handleChange} />

									<div className="form-input-icon">
										<img src={CallIcon} className='' alt="call_icon" />
									</div>	
								</>
							)}
						</Field>
					</div>

					{/*errors.phone &&
						<div className="mt-1 text-sm text-red">{errors.phone}</div>
					*/}
				</div>

				<div className='w-6/12 px-2 mb-4'>
					<div className="relative main-input-div">
						<Field name="accident_date">
							{({ field, form }) => (
								<>
									{/*<input type="date" name="accident_date" className="form-input" value={props.values.accident_date} placeholder="Date of Incident" onChange={props.handleChange} />*/}

									<DatePicker className={`form-input ${errors.accident_date ? 'border-red' : ''}`} showYearDropdown selected={props.values.accident_date}  placeholderText="Date Of Incident" maxDate={moment().toDate()} onChange={(date) => props.setFieldValue('accident_date', date) } />

									<div className="form-input-icon">
										<img src={CalendarIcon} className='' alt="call_icon" />
									</div>		
								</>
							)}
						</Field>
					</div>

					{/*errors.accident_date &&
						<div className="mt-1 text-sm text-red">{errors.accident_date}</div>
					*/}

				</div>

				<div className='w-6/12 px-2 mb-4'>
					<div className="relative main-input-div">
						<Field name="state">
							{({ field, form }) => (
								<>
									<select name="state" value={props.values.state} onChange={props.handleChange} className={`form-input bg-transparent ${errors.state ? 'border-red' : ''}`} placeholder="Select State">
										<option value="">Select State</option>
										{states.map(item => (
											<option key={item.state} value={item.code}>
												{item.state}
											</option>
										))}	
									</select>

									<div className="form-input-icon  pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
										<img src={DownArrow} className='' alt="down_arrow" />
									</div>
								</>
							)}
						</Field>
					</div>

					{/*errors.state &&
						<div className="mt-1 text-sm text-red">{errors.state}</div>
					*/}
				</div>

				<div className='w-full px-2 mb-2'>
					<div className='flex items-center gap-3'>
						<input type="checkbox" name="tnc" checked={props.values.tnc_checked} onChange={handleCheckboxChange} className="form-checkbox h-5 w-5 appearance-none border border-border1 bg-transparent !text-white rounded-[4px]" />
                    	<p className='font-normal text-[15px] text-white leading-7'>Agree <Link to="https://www.hakiminjurylaw.com/disclaimer/" target="_blank" className='text-white underline'>Terms & Conditions</Link> And <Link to="https://www.hakiminjurylaw.com/privacy-policy/" target="_blank" className='text-white underline'>Privacy Policy</Link></p>
					</div>
				</div>

				
			</div>
		</div>
	)
}

export default memo(GetResultStep)