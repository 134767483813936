import { memo } from 'react'
import FormImg from 'assets/v4/img/form-img1.svg'
import FormImg2 from 'assets/v4/img/form-img2.svg'
import FormImg3 from 'assets/v4/img/form-img3.svg'
import FormImg4 from 'assets/v4/img/form-img4.svg'

const Badges = (props) => {

	const badges = [
		{
			src: FormImg,
      		alt: "logo-img",
		},
		{
			src: FormImg2,
      		alt: "logo-img",
		},
		{
			src: FormImg3,
      		alt: "logo-img",
		},
		{
			src: FormImg4,
      		alt: "logo-img",
		},
	]

	return (
		<>
			<div className="mt-[10px] pt-[18px] pb-4 imageslistBox relative">
				<ul className="flex items-center gap-6 justify-around flex-wrap sm:flex-nowrap">
					{badges.map((item, index) => (
						<li key={index} className="w-[40%] sm:w-auto">
							<img src={item.src} className="m-auto max-w-full sm:max-w-[250px] h-auto" alt={item.alt} />
						</li>
					))}
				</ul>
			</div>
		</>
	)
}

export default memo(Badges)